import { React, useState } from "react";
import {
  useListContext,
  useNotify,
  useUnselectAll,
  useRefresh,
  Button,
  useListController,
} from "react-admin";
import { useTakeAction } from "../../../../lib/Hooks";

const BulkEnrollButtonComponent = function BulkEnrollButtonComponent() {
  const { selectedIds } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const [takeAction] = useTakeAction();
  const [isLoading, setIsLoading] = useState(false);
  const { refetch: refetchList } = useListController();

  const handleBulkEnroll = async () => {
    if (selectedIds.length === 0) {
      notify("Please select items to enroll", { type: "warning" });
      return;
    }

    setIsLoading(true);

    try {
      const results = await Promise.allSettled(
        selectedIds.map(async (id) => {
          try {
            await takeAction({
              resource: "enroll_member",
              id,
            });
            return { success: true };
          } catch (error) {
            return { success: false, error };
          }
        })
      );

      const errorCount = results.filter(
        (result) => !result.value.success
      ).length;

      await refetchList();

      if (errorCount > 0) {
        notify(`Failed to enroll ${errorCount} members.`, { type: "error" });
        unselectAll("member_benefit_selections");
      } else {
        notify(`Successfully enrolled selected members`, { type: "success" });
        refresh();
      }
    } catch (error) {
      notify(`Unexpected error: ${error.message}`, { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      label="Bulk Enroll"
      onClick={handleBulkEnroll}
      disabled={isLoading || selectedIds.length === 0}
    />
  );
};

const BulkEnrollButton = function BulkEnrollButton() {
  return <BulkEnrollButtonComponent key="bulk_enroll_button" />;
};

export default BulkEnrollButton;

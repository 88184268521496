const firstName = {
  source: "first_name",
  label: "First Name",
  sortable: true,
  reference: [["members", "member_id"]],
};
const lastName = {
  source: "last_name",
  validations: { required: true },
  label: "Last Name",
  sortable: true,
  reference: [["members", "member_id"]],
};
const status = {
  source: "status",
  label: "Status",
  sortable: true,
};
const startDate = {
  source: "start_date",
  label: "Start Date",
  sortable: true,
  type: "date",
};
const endDate = {
  source: "end_date",
  label: "End Date",
  sortable: true,
  type: "date",
};

export default {
  top_level_resource_config: {
    title: "Member Commuter Enrollments",
    backend_resource: "member_commuter_enrollments",
    attributes: [firstName, lastName, status, startDate, endDate],
    components: ["Show", "Edit"],
  },
  sub_resource_config: {
    tab_name: "Member Commuter Enrollments",
    backend_resource: "member_commuter_enrollments",
    linking_field: "company_commuter_enrollment_id",
    attributes: [firstName, lastName, status, startDate, endDate],
    components: ["Show", "List", "Edit"],
  },
};

const firstName = {
  source: "first_name",
  label: "First Name",
  sortable: true,
  reference: [["members", "member_id"]],
};
const lastName = {
  source: "last_name",
  validations: { required: true },
  label: "Last Name",
  sortable: true,
  reference: [["members", "member_id"]],
};
const status = {
  source: "status",
  label: "Status",
  sortable: true,
};
const contributionAmount = {
  source: "active_or_upcoming_monthly_contribution_amount",
  label: "Contribution Amount",
  sortable: true,
  type: "number",
};
const startDate = {
  source: "start_date",
  label: "Start Date",
  sortable: true,
  type: "date",
};
const endDate = {
  source: "end_date",
  label: "End Date",
  sortable: true,
  type: "date",
};

export default {
  top_level_resource_config: {
    title: "Member HSA Enrollments",
    backend_resource: "member_hsa_enrollments",
    attributes: [
      firstName,
      lastName,
      status,
      contributionAmount,
      startDate,
      endDate,
    ],
    components: ["Show", "Edit"],
  },
  sub_resource_config: {
    tab_name: "Member HSA Enrollments",
    backend_resource: "member_hsa_enrollments",
    linking_field: "company_hsa_enrollment_id",
    attributes: [
      firstName,
      lastName,
      status,
      contributionAmount,
      startDate,
      endDate,
    ],
    components: ["Show", "List", "Edit"],
  },
};
